import React from "react"
import { Link, graphql } from "gatsby"

import {
    MdArrowBack,
    MdPublic,
    MdGroup,
    MdList,
    MdMessage,
    MdImportantDevices

} from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const MarketingDigital = ({ data: { image1, marketing } }) => {


    let imgProd = marketing.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Marketing Digital`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{ backgroundImage: `url(${image1.childImageSharp.fluid.src})` }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                            <Link to="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                                <MdArrowBack
                                    color="#fff"
                                    size="2.3em"
                                />
                            </Link>

                        </div>
                        <div className="col-md-11 text-center">
                            <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Marketing Digital </h1>
                            <p className="breadcrumbs"><span className="mr-2">

                                <Link className="" to="/#services-section">Servicios</Link> > </span> <span>Marketing Digital</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-justify">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Marketing Digital</h3>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Si conoces un poco el mundo del marketing, habrás podido comprobar cómo este ha cambiado mucho en los últimos años. Ahora todo parece enfocarse hacia internet, el comercio electrónico y los contenidos digitales. Es la única manera de que la mayoría de empresas tienen para responder a las necesidades principales del mercado en el que nos movemos. </p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Cada vez recurrimos más al mundo digital, ese que igual que ha cambiado nuestras vidas, también lo ha hecho con las empresas. Estas tienen que adaptarse a los nuevos tiempos si quieren seguir subsistiendo. La importancia del marketing digital para una empresa es fundamental hoy en día. Es la clave de su éxito, la mejor manera que tenemos de acercarnos a ella y a lo que nos ofrece. </p>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;La tecnología nos ha cambiado completamente. El ritmo de vida tan acelerado que llevamos, ese que nos impide ir de compras, relacionarnos y saciar nuestra necesidad de ocio, necesita de soluciones rápidas, como las que nos ofrece el marketing digital.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>

                        <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >




                        </div>

                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section" style={{ padding: '2em 0' }}>
                <div className="container-fluid">
                    <div className="row justify-content-center mb-1 pb-3">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                            <h3 className="mb-4">Tipos de Marketing</h3>
                            <p>Ofrecemos los siguientes tipos de desarrollo de software, aplicando las mejores prácticas y estándares de diseño.</p>
                        </div>
                    </div>
                    <div className="row d-flex contact-info mb-0 justify-content-center mb-4">

                        <div className="col-md-6 col-lg-2 d-flex " style={{}}>
                            <div className="align-self-stretch box p-4 text-center" style={{ borderRadius: '15px', background: '#1565c0' }} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{ marginBottom: '3px', width: '50px', height: '50px' }}>
                                    <MdGroup color="#ffffff" size="2em" />
                                </div>
                                <h6 className="">Redes Sociales</h6>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-2 d-flex ">
                            <div className="align-self-stretch box p-4 text-center" style={{ borderRadius: '15px', background: '#1565c0' }} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{ marginBottom: '3px', width: '50px', height: '50px' }}>
                                    <MdPublic color="#ffffff" size="2em" />
                                </div>
                                <h6 className="">Web</h6>

                            </div>
                        </div>

                        <div className="col-md-6 col-lg-2 d-flex ">
                            <div className="align-self-stretch box p-4 text-center" style={{ borderRadius: '15px', background: '#1565c0' }} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{ marginBottom: '3px', width: '50px', height: '50px' }}>
                                    <MdList color="#ffffff" size="2em" />
                                </div>
                                <h6 className="">Blog</h6>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-2 d-flex ">
                            <div className="align-self-stretch box p-4 text-center" style={{ borderRadius: '15px', background: '#1565c0' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{ marginBottom: '3px', width: '50px', height: '50px' }}>
                                    <MdMessage color="#ffffff" size="2em" />
                                </div>
                                <h6 className="">Sms / Email Marketing</h6>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-2 d-flex ">
                            <div className="align-self-stretch box p-4 text-center" style={{ borderRadius: '15px', background: '#1565c0' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                <div className="icon d-flex align-items-center justify-content-center" style={{ marginBottom: '3px', width: '50px', height: '50px' }}>
                                    <MdImportantDevices color="#ffffff" size="2em" />
                                </div>
                                <h6 className="">Publicidad Online</h6>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </Layout>
    )
}

export default MarketingDigital

export const pageQuery = graphql`
  query FacByMarket {

    image1: file(relativePath: { eq: "marketing_banner.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    marketing: file(relativePath: { eq: "marketing.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
    }

  }
`
